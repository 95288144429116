<template>
  <div>
    <div class="info-content">
      <div v-for="({ type, content }, index) in text" :key="index" :class="type">
        {{content}}
      </div>
    </div>
    <div class="question-content" v-if="show">
      <el-form :model="userForm" :rules="rules" ref="userForm" :disabled="disabled">
        <template v-for="({ key, content }, index) in questions">
          <el-form-item :label="`${index + 1}: ${content[0]}`"
                        :prop="key" :key="key">
            <el-input v-model="userForm[key]">
              <template slot="append">{{content[1]}}</template>
            </el-input>
          </el-form-item>
        </template>
        <el-form-item v-if="!disabled">
          <el-button type="primary" :load="loading" @click="submitFn">提交</el-button>
          <el-button @click="clearFn">清空</el-button>
        </el-form-item>
      </el-form>
      <el-button v-if="disabled" type="primary" @click="goTurnFn">开始答卷</el-button>
    </div>
  </div>
</template>

<script>
import questions from '@/api/questions';

export default {
  data() {
    return {
      show: false,
      loading: false,
      disabled: false,
      answer: null,
      text: [
        { type: 'user', content: '尊敬的老师您好：' },
        { type: 'text', content: '欢迎您参加“光合作用”和“生态系统”这两个主题的初中生物学教师的学科教学知识的测试。本测试包含两种类型：（1）视频测验，（2）纸笔测验。 每个主题的测试题目的数量为6~7道题。请老师们按照研究者所规定的顺序进行测试。' },
        { type: 'text', content: '进行视频测验前，请老师们务必注意：每个视频的长度较短，一般在1分钟左右，每一个视频只能观看一次（模拟课堂实际情况，不能重复播放）。教师完成测试需要做到：' },
        { type: 'text', content: '1. 测试前务必调节好电脑的音量，确保视频有声音。' },
        { type: 'text', content: '2. 测试时仔细观看录像片段，听清楚师生对话的内容。' },
        { type: 'text', content: '3. 视频观看结束后，完成每个视频下方对应的4个问题。' },
        { type: 'text', content: '4. 一个视频的测试时间建议为10分钟。' },
        { type: 'text', content: '进行纸笔测验前，请老师们注意：纸笔测验需要和视频测验间隔2周以上。若您先做纸笔测验，至少间隔两周后再完成视频测验；若先做视频测验，也需要间隔两周后进行视频测验。建议老师们完成纸笔测验的一道大题的测试时间也为：10分钟。' },
        { type: 'text', content: '本测试工具由香港大学教育学院开发完成，由北京师范大学、华东师范大学、四川师范大学和香港大学教育学院的专家、博士生们进行联合审定，版权所有归香港大学教育学院所有。所有数据都会做匿名处理，不做个人排名，只用于帮助各省市的教研人员了解初中生物教师的学科教学知识水平，并推进我国初中生物学的教研工作。' },
        { type: 'text', content: '感谢您的参与，真挚感谢您为我国的基础教育事业做出的努力与贡献！让我们一起携手共同努力，期待祖国的科学教育会更好！' },
      ],
      questions: [],
      userForm: {},
      rules: {},
    };
  },
  mounted() {
    this.searchInitFn();
  },
  methods: {
    searchInitFn() {
      const arr = [
        questions.get(),
        questions.getQuestion({ type: 1 }),
      ];
      Promise.all(arr).then(([{ data }, { data: { question } }]) => {
        if (data) {
          const { answer, isCommit } = data;
          this.disabled = isCommit === 1;
          this.answer = answer;
        }
        this.questions = question;
        this.setFormFn();
      }).catch(() => {});
    },
    setFormFn() {
      this.questions.forEach(({ key, content }, index) => {
        this.questions[index].content = content.split('{}');
        if (this.answer) this.$set(this.userForm, key, this.answer[key]);
        else this.$set(this.userForm, key, '');
        this.rules[key] = [{
          required: true,
          message: '请填写问题',
          trigger: 'blur',
        }];
      });
      this.show = true;
    },
    submitFn() {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          questions.save({
            answer: this.userForm,
          }).then(() => {
            this.loading = false;
            this.disabled = true;
            this.$store.dispatch('actionChangeIsCommit', 1);
            this.$message.success('保存成功');
          }).catch(() => {
            this.loading = false;
          });
        }
      });
    },
    clearFn() {
      this.$refs.userForm.resetFields();
    },
    goTurnFn() {
      const infoJson = localStorage.getItem('userInfo');
      if (infoJson) {
        const { show } = JSON.parse(infoJson);
        if (show === 0) {
          this.$router.push('/guide');
        } else if (show === 1) {
          this.$router.push('/questionnairePhotosynthesis');
        } else if (show === 2) {
          this.$router.push('/guide');
        } else {
          this.$message.warning('权限异常');
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
  .info-content {
    padding: 0 15% 22px;
    font-size: 16px;
    line-height: 32px;
    .title {
      font-weight: 700;
      text-align: center;
    }
    .text {
      text-indent: 40px;
    }
    .congratulations {
      text-indent: 40px;
      padding: 20px 0;
    }
    .sincerely {
      margin-left: 60%;
    }
    .personnel, .mechanism {
      text-align: right;
    }
  }
  .question-content {
    .inline-item {
      display: inline-block;
    }
  }
</style>

<style lang="less">
  .question-content {
    .el-input-group__append {
      background: transparent;
      border: transparent;
      color: #606266;
      line-height: 40px;
    }
    .el-form-item__content {
      display: inline-block;
    }
    .el-input__inner {
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid #DCDFE6;;
    }
  }
</style>
